<template lang="pug">
.go-up-icon-wrapper(@click="scrollToTop")
  IconUpArrow(:class="isVisible ? 'go-up-arrow go-up-arrow_visible' : 'go-up-arrow'")
</template>

<script>
export default {
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    scrollToTop() {
      (window.scrollTo({
        top: 0,
        behavior: "smooth"
      }))
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      if (window.scrollY >= window.innerHeight && !this.isVisible) this.isVisible = true
      else if (window.scrollY < window.innerHeight && this.isVisible) this.isVisible = false
    })
  },
  unmounted() {
    window.removeEventListener('scroll', () => {
      if (window.scrollY >= window.innerHeight && !this.isVisible) this.isVisible = true
      else if (window.scrollY < window.innerHeight && this.isVisible) this.isVisible = false
    })
  }
}
</script>

<style lang="scss" scoped>
.go-up-icon-wrapper {
  position: fixed;
  right: 55px;
  top: 86vh;
  z-index: 111;
  mix-blend-mode: multiply;
}

.go-up-arrow {
  visibility: hidden;
  opacity: 0;
  color: $primary;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    color: $purple;
  }

  &_visible {
    visibility: visible;
    opacity: 1;
  }
}
</style>
